import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
    // import { setStore, getStore } from '@/utils/storage'

export default new Vuex.Store({
    state: {
        blogState: false, // 是否为修改模式
        musicState: false, // 是否为修改模式
        courseState: false, // 是否为修改模式
        login: false, // 是否登录
    },
    mutations: {
        ISLOGIN(state) {
            state.login = true;
        },
        blogState(state, judge) {
            state.blogState = judge
        },
        musicState(state, judge) {
            state.musicState = judge
        },
        courseState(state, judge) {
            state.courseState = judge
        },

    },
    getters: {
        showTitletime(state) {
            let time = state.musicState.titleTime
            if (time == 0) { return "00:00" } else {
                time = Math.round(time * Math.pow(10, 0)) / Math.pow(10, 0)
                var h;
                h = Math.floor(time / 60);
                //计算秒
                //算法：取得秒%60的余数，既得到秒数
                time = time % 60;
                //将变量转换为字符串
                h += '';
                time += '';
                //如果只有一位数，前面增加一个0
                h = (h.length == 1) ? '0' + h : h;
                time = (time.length == 1) ? '0' + time : time;
                return h + ':' + time;
            }
        },
        showCurrenttime(state) {
            let time = state.currentTime
            if (time == 0) { return "00:00" } else {
                time = Math.round(time * Math.pow(10, 0)) / Math.pow(10, 0)
                var h;
                h = Math.floor(time / 60);
                //计算秒
                //算法：取得秒%60的余数，既得到秒数
                time = time % 60;
                //将变量转换为字符串
                h += '';
                time += '';
                //如果只有一位数，前面增加一个0
                h = (h.length == 1) ? '0' + h : h;
                time = (time.length == 1) ? '0' + time : time;
                return h + ':' + time;
            }
        },
    },
    actions: {},
    modules: {}
})