// const BASEURL = "www.baidu.com"
// const LOCALURL = "https://thesongof404.top:3000/"
const LOCALURL = "http://47.102.103.50:3000/"
const BBSURL = "http://localhost:8888/"
const URL = {
    login: LOCALURL + 'login', //登录接口
    validate: LOCALURL + 'validate', //token校验接口
    blog: LOCALURL + 'blog', //文章数据
    upload: LOCALURL + 'upload', //上传
    music: LOCALURL + 'music', //获取音乐列表
    // music: 'http://47.102.103.50:3000/music', //获取音乐列表
    singledata: LOCALURL + 'singledata', //获取单条博客
    fileList: LOCALURL + 'file', //文件列表及小文件上传
    chunkUpload: LOCALURL + 'chunkupload/slice', //切片上传
    chunkMerge: LOCALURL + 'chunkupload/merge', // 切片合并
    course : LOCALURL + 'course', //
    
    // BBS小程序接口
    bbsUpload: BBSURL + 'course', // 文章上传
    
}

module.exports = URL