import Vue from 'vue'
import VueRouter from 'vue-router'
const Today = () =>
    import ('@/views/Today');
const Index = () =>
    import ('@/views/Index');
const Music = () =>
    import ('@/views/Music');
const Blogdetail = () =>
    import ('@/views/Blogdetail');
const Efficiency = () =>
    import ('@/views/Efficiency');
const About = () =>
    import ('@/views/About');
const Login = () =>
    import ('@/views/Login');
const Sup = () =>
    import ('@/views/Sup');
const Sin = () =>
    import ('@/views/Sin');
const Admin = () =>
    import ('@/views/Admin');
const Clockin = () =>
    import ('@/views/Clockin');
const Wrong = () =>
    import ('@/views/Wrong');
const Cloud = () =>
    import ('@/views/Cloud');
const Summary = () =>
    import ('@/views/Summary');
const File = () =>
    import ('@/views/File');
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: 'today',
        name: 'today',
        component: Index,
        meta: {
            keepAlive: true //不需要被缓存的组件
        },
        children: [{
                path: 'today',
                component: Today,
                meta: {
                    keepAlive: true //不需要被缓存的组件
                },
            },
            {
                path: 'summary',
                component: Summary,
                meta: {
                    keepAlive: true //不需要被缓存的组件
                },
            },
            {
                path: 'Efficiency',
                component: Efficiency,
                meta: {
                    keepAlive: true //不需要被缓存的组件
                },
            }, {
                path: 'About',
                component: About,
                meta: {
                    keepAlive: true //不需要被缓存的组件
                },
            },
        ]
    },
    {
        path: '/login',
        redirect: 'Sup',
        component: Login,
        children: [{
                path: '/Sup',
                component: Sup,
                meta: {
                    keepAlive: true //不需要被缓存的组件
                },
            },
            {
                path: '/Sin',
                component: Sin,
                meta: {
                    keepAlive: true //不需要被缓存的组件
                },
            }
        ]
    },
    {
        path: '/blogdetail',
        component: Blogdetail,
        meta: {
            keepAlive: false, //不需要被缓存的组件
            notCache: true
        },
    },
    {
        path: '/music',
        component: Music,
        meta: {
            keepAlive: true //不需要被缓存的组件
        },
    },
    {
        path: '/wrong',
        component: Wrong,
        meta: {
            keepAlive: true //不需要被缓存的组件
        },
    },
    {
        path: '/cloud',
        component: Cloud,
        meta: {
            auth: true
        },
        children: [{
            path: '/file',
            component: File,
            meta: {
                keepAlive: true //不需要被缓存的组件
            },
        }]
    },
    {
        path: '/clockin',
        component: Clockin,
    },
    {
        path: '/admin',
        component: Admin,
        meta: {
            auth: true
        },
        children: [{
                path: '/admin/write',
                component: () =>
                    import ('@/views/BManagement/Write')
            },
            {
                path: '/admin/summary',
                component: () =>
                    import ('@/views/BManagement/Summary')
            }, {
                path: '/admin/musicupload',
                component: () =>
                    import ('@/views/BManagement/Musicupload')
            },
            {
                path: '/admin/musiclist',
                component: () =>
                    import ('@/views/BManagement/MusicList')
            },
            {
                path: '/admin/course',
                component: () =>
                    import ('@/views/BManagement/Course')
            },
            {
                path: '/admin/courseData',
                component: () =>
                    import ('@/views/BManagement/CourseData')
            },{
                path: '/admin/file',
                component: () =>
                    import ('@/views/BManagement/File')
            },{
                path: '/admin/upload',
                component: () =>
                    import ('@/views/BManagement/Upload')
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router