import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getStore } from './utils/storage'
import url from '@/serviceAPI.config.js'
import uploader from 'vue-simple-uploader'
// import Vuex from 'vuex'
import 'default-passive-events'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// vue.use(Vuex)
Vue.use(uploader)
    // element按需引入
import { Checkbox,Table, TableColumn, Button, Radio, Collapse, CollapseItem, Card, Upload, MessageBox, Message, Avatar, PageHeader, Input, Form, Image, Menu, Submenu, MenuItemGroup, Icon, MenuItem, Switch, Row, Col, Carousel, CarouselItem, Slider, Dialog} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt =MessageBox.prompt
Vue.use(mavonEditor)
Vue.use(CollapseItem)
Vue.use(Collapse)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Button)
Vue.use(Radio)
Vue.use(Card)
Vue.use(Avatar)
Vue.use(PageHeader)
Vue.use(Input)
Vue.use(Form)
Vue.use(Image)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Icon)
Vue.use(MenuItem)
Vue.use(Switch)
Vue.use(Col)
Vue.use(Row)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Slider)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Checkbox)

    // Vue.component(Notification)
    // element按需引入

import axios from 'axios';
Vue.prototype.$http = axios;
// 请求拦截
axios.interceptors.request.use(config => {
    const token = getStore('token');
    if (token) {
        //表示用户已登录
        config.headers.common['Authorization'] = token;
    }
    return config
}, error => {
    return Promise.reject(error);
})
router.beforeEach((to, from, next) => {
    axios.post(url.validate, {}).then(ctx => {
        let data = ctx.data;
        if (data.state !== 999) {
            // 用户要登录

            if (to.matched.some(record => record.meta.auth)) {
                // 用户未登录 需要跳转登录页面
                next({
                    path: '/login',
                    query: {
                        redirect: to.fullPath
                    }
                })
            } else {
                next();
            }
        } else {
            // 保存用户的信息
            store.commit('ISLOGIN', data);
            if (to.path === '/login') {
                router.push({
                    path: next,
                })

            }
            next();
        }
    }).catch(error => {
        console.log(error);
    })
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')